import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import InputBorder from '../Border';
import Icon from '../../Icon';

require('./styles.scss');

export default function MultiSelectInput(props) {
    const {
        onChange,
        id,
        value,
        options,
        sm,
        horizontal,
        disabled,
        placeholder,
        className,
        contextMenu,
        toggleMenu,
        menuOpen,
    } = props;

    return (
        <div
            className={ClassNames('MultiSelectInput', className, {
                'MultiSelectInput--small': sm,
                'MultiSelectInput--horizontal': horizontal,
                'MultiSelectInput--vertical': !horizontal,
            })}
        >
            <InputBorder {...props} className="MultiSelectInput__display">
                <div
                    className={ClassNames('MultiSelectInput__summary', {
                        'MultiSelectInput__summary--placeholder': !value || !value.length,
                    })}
                    onClick={() => {
                        if (!disabled) toggleMenu();
                    }}
                    role="button"
                    tabIndex={0}
                >
                    {value?.length ? `${value.length} Selected` : placeholder || 'Select Items'}
                    {<Icon icon={menuOpen ? 'angle-up' : 'angle-down'} size="20px" />}
                </div>
                <div className="MultiselectInput__nuggets">
                    {value?.map((val) => {
                        const option = options.find((_option) => _option.value === val);
                        return (
                            <div className="MultiselectInput__nugget" key={option.value}>
                                {option.label}
                                <div
                                    onClick={() => {
                                        const updatedValue = value ? value.filter((item) => item !== option.value) : [];
                                        onChange(id, updatedValue);
                                    }}
                                    role="button"
                                    tabIndex={-1}
                                >
                                    <Icon icon="times" size="16px" />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </InputBorder>
            {contextMenu}
        </div>
    );
}

MultiSelectInput.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    contextMenu: PropTypes.node.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    menuOpen: PropTypes.bool,
    sm: PropTypes.bool,
    horizontal: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

MultiSelectInput.defaultProps = {
    onChange: () => {},
    id: '',
    value: [],
    options: [],
    sm: false,
    horizontal: false,
    menuOpen: false,
    disabled: false,
    placeholder: null,
    className: '',
};
